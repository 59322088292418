import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

// components
import Asset from '../../components/asset/Asset';
import Button from '../../components/button/Button';
import LinkButton from '../../components/button/LinkButton';
import Seo from '../../components/seo/SEO';

// svgs
import Plus from '../../svgs/circle-plus.svg';

// utils
import { renderSwitch } from '../../utils/Render';

const BlogList = () => {
    const {
        tags: { nodes: tags },
        posts: { nodes: posts },
        page,
    } = useStaticQuery(graphql`
        {
            tags: allCraftTagInterface {
                nodes {
                    title
                }
            }
            posts: allCraftBlogBlogEntry(sort: { fields: postDate, order: DESC }) {
                nodes {
                    id: remoteId
                    title
                    typeHandle
                    uri
                    richTextSimple
                    blogPostTagField {
                        title
                    }
                    singleAsset {
                        ... on Craft_images_Asset {
                            id: remoteId
                            imageFile {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED)
                                }
                            }
                        }
                        url
                        title
                    }
                }
            }
            page: craftBlogListBlogListEntry {
                title
                id: remoteId
                uri
                dateUpdated
                seo {
                    title
                    description
                    keywords {
                        keyword
                    }
                    advanced {
                        robots
                        canonical
                    }
                    social {
                        twitter {
                            image {
                                ... on Craft_images_Asset {
                                    url
                                }
                            }
                        }
                        facebook {
                            image {
                                ... on Craft_images_Asset {
                                    url
                                }
                            }
                        }
                    }
                }
                entryField {
                    title
                    uri
                    ... on Craft_blog_blog_Entry {
                        richTextStandard
                        richTextSimple
                        singleAsset {
                            ... on Craft_images_Asset {
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                                url
                                title
                            }
                        }
                    }
                }
                contentMatrix {
                    ...RichTextFragment
                    ...ProductsBlockFragment
                    ...TextGridBlockFragment
                    ...CalloutCtaBlockFragment
                    ...LargeCalloutBlockFragment
                    ...RainyDaysCalloutFragment
                    ...SingleMediaFragment
                    ...TextAndImageGridBlockFragment
                    ...ThumbnailImageGridFragment
                    ...TwoUpImageBlockFragment
                    ...ImageCarouselFragment
                    ...TextAndImageCtaBlockFragment
                    ...NewsletterSignupFragment
                    ...TextImageBlockFragment
                    ...PressBlockFragment
                    ...BannerBlockFragment
                    ...TimelineBlockFragment
                    ...FigureLinkGridFragment
                    ...ContactFormFragment
                    ...CloudGraphic
                    ...RainGraphic
                    ...StoremapperEmbed
                }
            }
        }
    `);

    const { seo } = page;
    const keywords = [];
    if (seo.keywords) {
        seo.keywords.forEach(keyword => {
            keywords.push(keyword.keyword);
        });
    }

    const content = page.contentMatrix;

    const [tag, setTag] = useState('All');
    const [list, setList] = useState([...posts.slice(0, 6)]);
    const [loadMore, setLoadMore] = useState(false);
    const [hasMore, setHasMore] = useState(posts.length > 6);

    const handleFilter = e => {
        setTag(e.target.value);
    };

    const handleLoadMore = () => {
        setLoadMore(true);
    };

    useEffect(() => {
        if (tag === 'All') {
            setList([...posts.slice(0, 6)]);
        } else {
            setList([...posts.filter(obj => obj.blogPostTagField[0].title === tag).slice(0, 6)]);
        }
    }, [tag]);

    useEffect(() => {
        if (loadMore && hasMore) {
            const currentLength = list.length;

            if (tag === 'All') {
                const isMore = currentLength < posts.length;
                const nextResults = isMore ? posts.slice(currentLength, currentLength + 6) : [];
                setList([...list, ...nextResults]);
                setLoadMore(false);
            } else {
                const filteredPosts = posts.filter(obj => obj.blogPostTagField[0].title === tag);
                const isMore = currentLength < filteredPosts.length;
                const nextResults = isMore
                    ? filteredPosts.slice(currentLength, currentLength + 6)
                    : [];
                setList([...list, ...nextResults]);
                setLoadMore(false);
            }
        }
    }, [list, loadMore, hasMore]);

    useEffect(() => {
        if (tag === 'All') {
            if (list.length < posts.length) {
                setHasMore(true);
            } else {
                setHasMore(false);
            }
        } else {
            const filteredPosts = posts.filter(obj => obj.blogPostTagField[0].title === tag);
            if (list.length < filteredPosts.length) {
                setHasMore(true);
            } else {
                setHasMore(false);
            }
        }
    }, [list]);

    const handleKeyDown = e => {
        if (e.keyCode === 32 || e.keyCode === 13) handleLoadMore();
    };

    const postsEl = list.map(entry => (
        <li key={entry.id} className="relative list-style-none">
            <Link to={`/${entry.uri}`}>
                {entry.singleAsset[0] ? (
                    <div style={{ height: '240px' }} className="m-24">
                        <Asset
                            border
                            data={entry.singleAsset[0].imageFile}
                            title={entry.singleAsset[0].title}
                        />
                    </div>
                ) : null}

                <h3 className="h3">{entry.title}</h3>
                {entry.richTextSimple ? (
                    <div dangerouslySetInnerHTML={{ __html: entry.richTextSimple }} />
                ) : null}

                <Plus className="overlay-button overlay-button--offset" role="button" />
            </Link>
        </li>
    ));

    return (
        <>
            <Seo
                title={seo.title}
                canonical={seo.advanced.canonical}
                description={seo.description}
                facebookImage={seo.social.facebook.image?.url}
                keywords={keywords}
                robots={seo.advanced.robots}
                twitterImage={seo.social.twitter.image?.url}
                modifiedTime={page.dateUpdated}
            />
            <div className="spacing--small">
                <div className="grid grid--auto-1 m-120">
                    {page.entryField[0] && page.entryField[0].singleAsset[0].imageFile ? (
                        <Link to={`/${page.entryField[0].uri}`}>
                            <Asset
                                border
                                data={page.entryField[0].singleAsset[0].imageFile}
                                title={page.entryField[0].singleAsset[0].title}
                            />
                        </Link>
                    ) : null}

                    {page.entryField[0] ? (
                        <div>
                            <div className="m-42">
                                <Link to={`/${page.entryField[0].uri}`}>
                                    <h1
                                        className="h1"
                                        dangerouslySetInnerHTML={{
                                            __html: page.entryField[0].title,
                                        }}
                                    />
                                </Link>
                                {page.entryField[0].richTextSimple ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: page.entryField[0].richTextSimple,
                                        }}
                                    />
                                ) : null}
                            </div>
                            <div className="flex justify-center">
                                <LinkButton
                                    to={`/${page.entryField[0].uri}`}
                                    large
                                    copy="Read more"
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="flex wrap justify-between align-center">
                    {posts.length > 0 ? <h1 className="h1">Latest Blog Posts</h1> : null}

                    <select
                        onChange={handleFilter}
                        className="input-button input-button--outline m-32">
                        <option value="All">View: all</option>
                        {tags.map(item => (
                            <option key={item.title} value={item.title}>
                                {item.title}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="grid grid--3 m-64">{postsEl}</div>
                <div className="flex justify-center full m-120">
                    {hasMore ? (
                        <div
                            onKeyDown={handleKeyDown}
                            role="button"
                            tabIndex={0}
                            onClick={handleLoadMore}>
                            <Button large copy="Load More" />
                        </div>
                    ) : null}
                </div>

                {content.map((block, i) => renderSwitch(block, i))}
            </div>
        </>
    );
};

export default BlogList;
